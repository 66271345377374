import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import zxcvbn from 'zxcvbn'
import _ from 'lodash'
import { Field } from 'formik'
import { FormItem } from '../../components'

import * as S from './styles'

const strengthList = ['very weak', 'weak', 'medium', 'strong', 'very strong']

const contextGuesses = [
  'intrmodl',
  'intermodal',
  'rms',
  'rail',
  'management',
  'services',
  'yard',
  'train',
  'vehicle',
  'worker',
  'driver',
  'audit',
  'list',
  'billing',
  'analytics',
  'vcr',
]

const requiredMessage = 'Required field'

function PasswordStrength({
  password,
  username,
  firstName,
  lastName,
  fieldComponent,
  passwordTitle,
  confirmTitle,
  titleType,
  yardList,
  validationType,
}) {
  const getScore = useCallback(
    (passwordValue) => {
      const yardGuesses = _.chain(yardList)
        .map(({ name }) => name.split(' '))
        .flatten()
        .value()
      const notNilPassword = _.isNil(passwordValue) ? '' : passwordValue
      const userInformationGuesses = [username, firstName, lastName]

      const { score } = zxcvbn(notNilPassword, [
        ...contextGuesses,
        ...userInformationGuesses,
        ...yardGuesses,
      ])

      return score
    },
    [username, firstName, lastName, yardList],
  )

  const validatePassword = useCallback((value) => {
    if (_.isEmpty(value)) {
      return requiredMessage
    }

    const hasOnlyDigits = /^\d+$/g.test(value)

    if (!hasOnlyDigits) {
      return 'Password must have only numeric digits.'
    }

    if (value.length !== 6) {
      return 'Password must have exactly 6 digits.'
    }

    return undefined
  }, [])

  const validateStrengthPassword = useCallback(
    (value) => {
      if (!value) {
        return requiredMessage
      }

      const score = getScore(value)

      if (score < 3) {
        return `Password must not be ${strengthList[score]}`
      }

      return undefined
    },
    [getScore],
  )

  const validatePasswordConfirm = useCallback(
    (value) => {
      if (!value) {
        return requiredMessage
      }

      if (!_.eq(value, password)) {
        return "Passwords don't match"
      }

      return undefined
    },
    [password],
  )

  const score = getScore(password)

  function renderStrengthIndicator() {
    if (_.isEmpty(password) || score < 2) {
      return null
    }

    return (
      <S.PasswordScore>This password is {strengthList[score]}</S.PasswordScore>
    )
  }

  const passwordOtherProps = {
    [titleType]: passwordTitle,
    autoFocus: true,
  }

  const confirmOtherProps = {
    [titleType]: confirmTitle,
  }

  return (
    <>
      <Field
        type="password"
        name="password"
        component={fieldComponent}
        validate={
          validationType === 'strength'
            ? validateStrengthPassword
            : validatePassword
        }
        {...passwordOtherProps}
      />
      {validationType === 'strength' && renderStrengthIndicator()}
      <Field
        type="password"
        name="passwordConfirm"
        component={fieldComponent}
        validate={validatePasswordConfirm}
        {...confirmOtherProps}
      />
    </>
  )
}

PasswordStrength.propTypes = {
  password: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  firstName: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  yardList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fieldComponent: PropTypes.elementType,
  passwordTitle: PropTypes.string,
  confirmTitle: PropTypes.string,
  titleType: PropTypes.oneOf(['placeholder', 'label']).isRequired,
  validationType: PropTypes.oneOf(['strength', 'numeric']),
}

PasswordStrength.defaultProps = {
  fieldComponent: FormItem,
  passwordTitle: 'Password',
  confirmTitle: 'Password Confirmation',
  validationType: 'strength',
}

export default PasswordStrength
